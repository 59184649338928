import React from "react";
import DashboardWrapper from "../../utils/pageWrappers/dashboardWrapper";
import HomePage from "../../src/components/Misc/HomePage";

const Index = () => {
  return <HomePage />;
};

Index.displayName = "Index";

Index.defaultProps = {
  AuthUserInfo: null
};

export default DashboardWrapper(Index, true, true);
