/*
 * ------------------------
 * File: /src/components/loading.js
 * Project: scap-viewer
 * ------------------------
 * Copyright colortokens.com - All Rights Reserved.
 * The intellectual and technical concepts contained herein are proprietary to colortokens.com
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 * ------------------------
 */

import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

class Init extends React.Component {
  render() {
    return (
      <div
        className="fullPageLoading"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100vh",
          overflow: "hidden"
        }}
      >
        <CircularProgress size={30} />
      </div>
    );
  }
}

export default Init;
