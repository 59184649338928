import React from "react";
import Loading from "@components/Common/Loading";
import Router from "next/router";
import { LOCAL_STORAGE_KEYS, ROLES } from "src/constants";

export default class HomePage extends React.Component {
  componentDidMount() {
    let storedUser = window.localStorage.getItem(LOCAL_STORAGE_KEYS.LAST_USER);
    storedUser = JSON.parse(storedUser);
    let routeTo = "/xcloud/home";
    if (storedUser && (storedUser.hasResources || storedUser?.role === ROLES.VIEWER)) {
      routeTo = "/xcloud/home";
    } else {
      routeTo = "/xcloud/cloud-accounts";
    }
    Router.replace(routeTo);
  }

  render() {
    return <Loading />;
  }
}
